import { INav } from '~/interfaces/menus/nav'
import categoryApi from '~/api/category'

const dataHeaderNavigation: any = [
    // {
    //     name: 'Home',
    //     url: '/',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             { name: 'Home 1', url: '/' },
    //             { name: 'Home 2', url: '/home-two' },
    //             { name: 'Offcanvas Cart', url: '/offcanvas-cart' }
    //         ]
    //     }
    // },
    {
        name: 'Shop',
        url: '/shop/catalog',
        submenu: {
            type: 'menu',
            menu: []
        }
    },
    {
        name: 'Orders',
        url: '/shop/cart',
        submenu: {
            type: 'menu',
            menu: [
                {
                    name: 'Cart',
                    url: '',
                    children: [
                        { name: 'Orders In Cart', url: '/shop/cart' },
                        // { name: 'Orders Checked out', url: '/shop/checkout' },
                        // { name: 'Cancelled Orders', url: '' },
                        // { name: 'Save Drafts', url: '' }
                    ]
                },
                { name: 'My Orders', url: `${process.env.URL}/dashboard/myorders/` },
                { name: 'My RFQs', url: `${process.env.URL}/dashboard/myrfqs/` },
                // { name: 'Wishlist', url: '/shop/wishlist' },
                // { name: 'Compare', url: '/shop/compare' },
                // {
                //     name: 'Track Order',
                //     children: [
                //         { name: 'New Orders', url: '' },
                //         { name: 'Orders Received', url: '' },
                //         { name: 'Draft Orders', url: '' },
                //         { name: 'Order History', url: '' }
                //     ]
                // },
                // {
                //     name: 'Request For Quote',
                //     children: [
                //         { name: 'RFQ Submitted', url: '' },
                //         { name: 'Quotes Received', url: '' },
                //         { name: 'Quotes To Orders', url: '' }
                //     ]
                // },
                // {
                //     name: 'Items On Approval',
                //     children: [
                //         { name: 'My Requests', url: '' },
                //         { name: 'Returned', url: '' },
                //         { name: 'Converted To Sales', url: '' }
                //     ]
                // },
                { name: 'Terms And Conditions', url: '/site/terms' },
            ]
        }
    }, {
        name: 'Catalogues',
        url: '/catalogues'
    },
    {
        name: 'Account',
        url: '/account/dashboard',
        submenu: {
            type: 'menu',
            menu: [
                // { name: 'Update Profile', url: '/account/profile' },
                // { name: 'Edit Office Address', url: '' },
                // { name: 'Edit Shipping Address', url: '' },
                // { name: 'Change Password', url: '' },
                // { name: 'Forgot Password', url: '' },
                // { name: 'Recovery Question', url: '' },
                { name: 'Update Profile', url: `${process.env.URL}/dashboard/account/update-profile/` },
                { name: 'My Feedback', url: `${process.env.URL}/dashboard/myfeedbacks/` },
                { name: 'My Incidents', url: `${process.env.URL}/dashboard/myincidents/` }
            ]
        }
    },
    {
        name: 'Company',
        submenu: {
            type: 'menu',
            menu: [
                {
                    name: 'About Us',
                    url: '/site/about-us',
                },
                {
                    name: 'Contact Us',
                    children: [
                        { name: 'Contact Us', url: '/site/contact-us' },
                        { name: 'Feedback', url: '/site/feedback' }
                    ]
                },
                {
                    name: 'Communications',
                    children: [
                        // { name: 'Announcements', url: '' },
                        // { name: 'Notifications', url: '' },
                        { name: 'Blog', url: '/blog/category-left-sidebar' },
                        { name: 'News', url: '/news/category-left-sidebar' }
                    ]
                },
                {
                    name: 'Policies',
                    children: [
                        { name: 'Terms Of Use', url: '/site/terms' },
                        // { name: 'Privacy Policy', url: '' }
                    ]
                },
            ]
        }
    }
    // {
    //     name: 'Shop Grid',
    //     url: '/shop/category-grid-3-columns-sidebar',
    //     children: [
    //         { name: '3 Columns Sidebar', url: '/shop/category-grid-3-columns-sidebar' },
    //         { name: '4 Columns Full', url: '/shop/category-grid-4-columns-full' },
    //         { name: '5 Columns Full', url: '/shop/category-grid-5-columns-full' }
    //     ]
    // },
    // { name: 'Shop List', url: '/shop/category-list' },
    // { name: 'Shop Right Sidebar', url: '/shop/category-right-sidebar' },
    // {
    //     name: 'Product',
    //     url: '/shop/product-standard',
    //     children: [
    //         { name: 'Product', url: '/shop/product-standard' },
    //         { name: 'Product Alt', url: '/shop/product-columnar' },
    //         { name: 'Product Sidebar', url: '/shop/product-sidebar' }
    //     ]
    // },
    // { name: 'Order Success', url: '/shop/checkout/success' },
    // { name: 'Wishlist', url: '/shop/wishlist' },
    // { name: 'Compare', url: '/shop/compare' },
    // { name: 'Track Order', url: '/shop/track-order' }
    // {
    //     name: 'Catalogues',
    //     url: '/catalogues',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             { name: 'Login', url: '/account/login' },
    //             { name: 'Dashboard', url: '/account/dashboard' },
    //             { name: 'Order History', url: '/account/orders' },
    //             { name: 'Order Details', url: '/account/orders/5' },
    //             { name: 'Address Book', url: '/account/addresses' },
    //             { name: 'Change Password', url: '/account/password' },
    //             { name: 'Request For Qoute', url: '/site/request-quote' }
    //         ]
    //     }
    // },
    // {
    //     name: 'Blog',
    //     url: '/blog/category-classic',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             { name: 'Blog Classic', url: '/blog/category-classic' },
    //             { name: 'Blog Grid', url: '/blog/category-grid' },
    //             { name: 'Blog List', url: '/blog/category-list' },
    //             { name: 'Blog Left Sidebar', url: '/blog/category-left-sidebar' },
    //             { name: 'Post Page', url: '/blog/post-classic' },
    //             { name: 'Post Without Sidebar', url: '/blog/post-full' }
    //         ]
    //     }
    // },
    // {
    //     name: 'Pages',
    //     url: '/site/about-us',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             { name: 'About Us', url: '/site/about-us' },
    //             { name: 'Contact Us', url: '/site/contact-us' },
    //             { name: 'Contact Us Alt', url: '/site/contact-us-alt' },
    //             { name: '404', url: '/site/not-found' },
    //             { name: 'Terms And Conditions', url: '/site/terms' },
    //             { name: 'FAQ', url: '/site/faq' },
    //             { name: 'Components', url: '/site/components' },
    //             { name: 'Typography', url: '/site/typography' }
    //         ]
    //     }
    // }
]

export default dataHeaderNavigation
let menu: any = dataHeaderNavigation[ 0 ].submenu.menu
loadCategories()

async function loadCategories() {
  const { data }: any = await categoryApi.list({ 'page[size]': 1000 });
  console.log('DATA: ', data);
  const url = (data) => {
    return `/shop/catalog/${data.id}`;
  };

    if (data) {
        data.forEach((e: any) => {
            url(e)
            e.children = e.children?.data

            if (e && e.children) {
                e.children.forEach((f: any) => {
                    delete f.children
                    url(f)
                });
            }

            if (e.parentid == 0) { menu.push(e) }
        });
    }
}